@import '../../css/variables';
@import '../../css/typography';

.teamEmailAlertModal {
    box-sizing: border-box;

    .modal-dialog {
        max-width: 395px;
        max-height: 170px;
        transform: translate(0%, 50%) !important;
    }

    &-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 32px 32px 0 32px;

        h1 {
            width: 90%;
        }

        &--close {
            position: absolute;
            top: 17px;
            right: 25px;
            width: 16px;
            height: 16px;
            fill: $indigo;
            &:hover {
                cursor: pointer;
            }
        }
    }

    &-body {
        width: 100%;
        margin: 0px 24px 32px 0px;
        padding: 0px 64px 0px 32px;
        @include font-source(14px, $gray-800, $font-weight-semibold);
    }
}

.teamNotificationsConfirmationEmail {
    box-sizing: border-box;

    .modal-dialog {
        max-width: 620px;
        transform: translate(0%, 50%) !important;
    }

    &-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 32px 32px 0 32px;

        h1 {
            width: 90%;
        }

        &--close {
            position: absolute;
            top: 17px;
            right: 25px;
            width: 16px;
            height: 16px;
            fill: $indigo;
            &:hover {
                cursor: pointer;
            }
        }
    }

    &-body {
        width: 100%;
        margin: 0px 24px 32px 0px;
        padding: 0px 64px 0px 32px;
        @include font-source(14px, $gray-800, $font-weight-semibold);
    }

    &-footer {
        padding: 16px;
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        border-top: 1px solid rgba(0, 0, 0, 0.09);
    }
}

.teamEmails {
    display: flex;
    flex-flow: row nowrap;
    padding: 24px 0 33px 0;

    &-label {
        flex: 0 0 125px;
        color: $gray-600;
    }

    &-body {
        flex: 1;
    }
}
